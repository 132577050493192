.header-menu-container {
    background-color: #333333;
    display: flex;
    height: 83px;
}

.header-menu-container-offline,
.header-menu-container-offline .left-section,
.header-menu-container-offline .right-section,
.header-menu-container-offline .center-section {
    background-color: #be0000;
}


.header-menu-container button {
    background-color: #333333;
    border: 0;
}

.header-menu-container .left-section {
    flex: 0.1;
}

.header-menu-container .right-section {
    flex: 0.1;
}

.header-menu-container .center-section {
    display: flex;
    flex: 0.8 1;
    align-items: center;
    justify-content: center;
}

.header-menu-container .center-section label {
    color: #FFFFFF;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */

    text-align: center;
    margin-left: 5px;
}


@media screen and (max-width: 600px) {
    .header-menu-container .left-section {
        flex: 0.2;
    }

    .header-menu-container .right-section {
        flex: 0.2;
    }

    .header-menu-container .center-section {

        flex: 0.6;
    }
}