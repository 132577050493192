.card-detail-container {
    /* height: 650px;
    overflow: auto; */
}

.card-detail-container .scrollable {
    height: 330px;
    overflow: auto;
}

.card-detail-container .scrollable-alone {
    height: 430px;
    overflow: auto;
}

@media screen and (max-height: 570px) {
    .card-detail-container .scrollable {
        height: 260px;
        overflow: auto;
    }

    .card-detail-container .scrollable-alone {
        height: 430px;
        overflow: auto;
    }
}

@media screen and (min-height: 570px)and (max-height: 670px) {
    .card-detail-container .scrollable {
        height: 330px;
        overflow: auto;
    }

    .card-detail-container .scrollable-alone {
        height: 430px;
        overflow: auto;
    }
}



@media screen and (min-height: 670px) and (max-height: 785px) {
    .card-detail-container .scrollable {
        height: 400px;
        overflow: auto;
    }

    .card-detail-container .scrollable-alone {
        height: 530px;
        overflow: auto;
    }
}

@media screen and (min-height: 785px) {
    .card-detail-container .scrollable {
        height: auto;
        overflow: auto;
    }

    .card-detail-container .scrollable-alone {
        height: auto;
        overflow: auto;
    }
}


.card-detail-container .card-loading {
    display: inline-block;
    align-items: center;
    justify-items: center;
    text-align: center;
    width: 100%;
    padding-top: 50px;
}


.fixed {
    background-color: #F6F6F6;
    height: 135px;
    padding: 10px;
}

.card-detail-container .top {
    display: flex;
    margin-bottom: 5px;
}


.card-detail-container .top .right {
    justify-content: flex-end;
    flex: 0.5;
    display: flex;
}
.card-detail-container .top .right button.btn-print
{
    border-color: black;
    color: black;    
}
.card-detail-container .top .right button {
    border: 2px solid #F84E4E;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 13px 29px;
    color: #F84E4E;
    background-color: transparent;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    margin-left: 5px;
}

.card-detail-container .top .left {
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 50px;
    flex: 1.5;
}

.card-detail-container .middle {
    margin-top: 10px;
    background: #FFFFFF;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding: 10px 8px
}



.card-detail-container .middle p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */


    color: #333333;
}

.card-detail-container .middle .title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    flex-direction: column;
}

.card-detail-container .middle .external-id
{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
}

.card-detail-container .middle .warning {
    background: #F7EFA8;
    border-radius: 6px;
    min-height: 27px;
    color: #655C03;
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    padding: 2px 7px
}

.card-detail-container .middle:nth-child(3) {
    margin-top: 0;
    background: #F8F8F8;
    border-radius: 0;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.card-detail-container .bottom {
    margin-top: 10px;
}

.card-detail-container .bottom .note {
    height: auto;
    min-height: 50px;
    background-color: #e0e0e0;
    border-radius: 6px;
    padding: 14px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */


    color: #686D74;
    margin-bottom: 10px;
}

.card-detail-container .items-container {

    overflow: auto;
    background: #FFFFFF;
    border: 1px solid #E9E9E9;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.card-detail-container .items-container.filled {
    padding: 15px 10px;
    border: 1px solid rgb(0 0 0 / 5%);
}

.card-detail-container .cartitem {
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;
}

.card-detail-container .cartitem .name {
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.023em;
    font-weight: 500;
    color: #484848;
    flex-grow: 1;
    padding: 0 11px;
    width: 100%;
}

.card-detail-container .cartitem .name .options {
    display: flex;
    flex-direction: column;
}

.card-detail-container .cartitem .name .options span {
    font-size: 10px;
    line-height: 15px;
    color: #737373;
}

.card-detail-container .cartitem .price {
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.023em;
    color: #484848;
}

.card-detail-container .cartitem .quantity {
    display: flex;
    justify-content: center;
    width: 15px;
    margin: 0 8px;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.023em;
    color: #484848;
}

.card-detail-container .summary {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: #F8F8F8;
}

.card-detail-container .summary .summary-row {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    justify-content: space-between;
    margin-bottom: 13px;
}

.card-detail-container .summary .summary-row span {
    font-size: 12px;
    line-height: 115%;
    letter-spacing: 0.023em;
    color: #737373;
}

.card-detail-container .summary-total {
    background-color: #F2F2F2;
    padding: 10px;
    margin-bottom: 10px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.card-detail-container .summary .summary-row.total span {
    font-weight: 500;
    font-size: 13px;
    line-height: 100%;
    letter-spacing: 0.023em;
    color: #484848;
}

.card-detail-container .summary-total .summary-row.total {
    margin-bottom: 0;
}


.card-detail-container .fixed .delivery-time {
    width: 100%;
    height: 48px;
    background-color: #E4E4E4;
    border-radius: 6px;
    margin-bottom: 15px;
}



.card-detail-container .fixed .delivery-time .nav-pills {
    margin: 0 !important;
    padding: 3px !important;
    font-size: 12px;
    border: 0 !important;
    width: 100%;
    overflow-x: hidden;
    white-space: nowrap;
    display: inline-block;
    padding-right: 108px !important;
}

.card-detail-container .fixed .delivery-time .nav-pills::-webkit-scrollbar {
    height: 7px;
}

.card-detail-container .fixed .delivery-time .nav-pills .nav-item {
    border: 0;
    display: inline-block;
}

.card-detail-container .fixed .delivery-time .nav-pills .nav-link {
    user-select: none;
    /* supported by Chrome and Opera */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    border: 0;
    border-radius: 6px;
    padding: 13px 10px 12px 10px;
    margin-left: 3px;
    /* background: linear-gradient(0deg, #fbfbfb, #fbfbfb), #fbfbfb; */
    border-radius: 5px;
    color: #686D74;
    width: 70px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 115%;
    /* identical to box height, or 15px */

    letter-spacing: 0.023em;
}

.card-detail-container .fixed .delivery-time .nav-pills .nav-link.active {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    background: linear-gradient(0deg, #fbfbfb, #fbfbfb), #fbfbfb;
    color: #373C41;
}

.card-detail-container .fixed .delivery-time .nav-pills .nav-item:last-child {
    display: inline-flexbox !important;
    position: fixed;
    right: 23px;
}

.card-detail-container .fixed .delivery-time .nav-pills .nav-item:last-child .nav-link {

    border: 1.5px solid #333333;

    background: #353535;
    color: #fff;
    border-radius: 6px;
    width: 103px;
    text-align: center;

    padding: 13px 10px 12px 5px;
}

.card-detail-container .fixed .btn-section {
    display: flex;
}

.card-detail-container .fixed .btn-section button,
.card-detail-container .fixed .btn-section label {
    flex: 1;
    font-size: 12px;
    text-align: center;
}

.card-detail-container .fixed .btn-section label {
    flex: 0.5;
    padding-top: 10px;
    margin-right: 10px;
    font-size: 20px;
}

.card-detail-container .fixed .btn-section .other-time {
    flex: 0.5;
    border: 1.5px solid #333333;
    box-sizing: border-box;
    border-radius: 6px;
    background: transparent;
    padding: 15px;
    margin-right: 10px;
}

.card-detail-container .fixed .btn-section .order-delivery-confirm {
    background: #6DC287;
    border: 0;
    border-radius: 6px;
    padding: 15px 5px;
    text-align: center;
    color: #fff
}

.card-detail-container .empty-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 128px;
}

.card-detail-container .empty-list img {
    max-width: 250px;
}


.card-detail-container .empty-list p {
    margin-top: 50px;
}

@media screen and (max-width: 993px) {
    .fixed {
        position: sticky;
        bottom: 0;
    }

    /* .card-detail-container .scrollable {
        padding-bottom: 100px;
    } */

    /* .card-detail-container .fixed .delivery-time .nav-pills .nav-item:last-child {
        bottom: 95px;
    } */

}

.card-detail-accept-loader{
    height:15px !important;
    width:15px !important
}