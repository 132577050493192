.tipspopup .btn-cancel {
    flex: 1;
    height: 45px;
    border-radius: 6px;
}


.tipspopup .btn-cancel {
    border: 2px solid #333333;
    box-sizing: border-box;
    background: transparent;
}

.tipspopup .modal-content {

    background: #F1F1F1;
}

.tipspopup .modal-body {
    width: 100%;
    padding: 14px 15px !important;
    text-align: left;
}

.tipspopup .modal-body .textbox-container {
    padding-left: 10px;
    background: #FFFFFF;
    border: 1px solid #F8F8F8;
    font-size: 12px;
}

.tipspopup .modal-body .textbox-container input {
    margin-left: unset;
    margin-right: unset;
    padding: unset;
    resize: none;
    flex: 1;
    width: 100%;
    text-align: left;
    font-size: 14px;
    padding-right: 3px;
    box-sizing: border-box;
    border-radius: 6px;
}


.tipspopup .modal-body .alert {
    text-align: left !important;
    font-size: 9pt;
}

.tipspopup .modal-body h2 {

    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-size: 12px;

    color: #9F9F9F;
    padding: 0 0 0 10px
}


.tipspopup .modal-body .btn-group {
    width: 100%;
    height: auto;
    display: flex;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
}

.tipspopup .modal-body .btn-done {
    margin-left: 10px;
    height: 45px;
    flex: 1.1;
    border: 2px solid #6DC287;
    box-sizing: border-box;
    border-radius: 6px;
    color: #FFFFFF;
    background-color: #6DC287;
    line-height: 18px;
}

.tipspopup .modal-body p {
    margin-top: 20px;
}

@media screen and (max-width:768px) {
    .tipspopup {
        position: sticky !important;
        bottom: 0 !important;
        left: 0 !important;
        z-index: 1055 !important;
        width: 100% !important;
        height: unset !important;
        overflow-x: unset !important;
        overflow-y: unset !important;
        outline: 0 !important;
    }

    .tipspopup .modal-dialog {
        margin: 0 !important;
    }

    .tipspopup .modal-content {
        border-radius: 0 !important;
    }

    .modal-sm {
        max-width: 100%;
    }
}