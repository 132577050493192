.orders-navbars .nav {

    background: #dadada !important;
    border-radius: 6px !important;
    padding: 5px;
}

.orders-navbars .nav .nav-item {
    flex: 1;
    text-align: center;
}

.orders-navbars .nav .nav-item a {
    color: #686D74;
}

.orders-navbars .nav .nav-item a.active {
    background: #FFFFFF !important;
    color: #373C41;
}