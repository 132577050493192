.textarea-container {
  height: 150px;
  width: 100%;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 14px;
  background: #fbfbfb;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 6px;
}

.textarea-container .left-icon {
  width: 16px;
}

.textarea-container .prefix-phone-number {
  width: 50px;
  height: 100%;
  line-height: 4 !important;
  color: #a9a9a9;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  border-right: 1px solid #eaeaea;
  padding-right: 10px;
  margin-left: 10px;
}

.textarea-container textarea {
  flex-grow: 1;
  width: 100%;
  background-color: transparent;
  border: none !important;
  outline: none !important;
  margin-left: 14px;
  margin-right: 14px;
  height: 100%;
  padding: 10px 0;
}

.textarea-container textarea:focus-within,
.textarea-container textarea:focus-visible,
.textarea-container textarea:hover,
.textarea-container textarea:focus {
  border: none !important;
}

.textarea-container textarea:read-only {
  color: #737373;
}

.textarea-container textarea::placeholder {
  color: #a9a9a9;
}