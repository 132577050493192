@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=PT+Serif&display=swap");

* {
    font-family: "Poppins", sans-serif;
}

body {
    background-color: #E5E5E5;
}

.alert {
    font-size: 9pt;
    padding: 0.25rem 0.5em;
}