.auth-container {
    margin-top: 80px;
}

.auth-container .logo {
    display: flex;
    justify-content: center;
    margin-bottom: 63px;
}

.auth-container .logo img {
    padding: 20px 0;
}

.auth-container .login-form-section {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}