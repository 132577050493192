.offcanvas-title {
    font-family: Poppins;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #9F9F9F;
}

.list-group {
    display: unset !important;
}

.offcanvas-header {
    padding: 1rem 2rem !important;
}

.list-group-item {
    border: 0 !important;

    font-weight: 500 !important;
}

.logout-btn {
    padding: 1rem 0.5rem;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */
    bottom: 20px;

    color: #F15E5E;
    border: 0;
    background-color: transparent;
    position: absolute;
    text-align: left;
    width: 80%;
}

.logout-logo {
    margin-left: 10px;
    color: #F15E5E;
}

@media screen and (max-width: 600px) {
    .offcanvas-start {
        width: 300px !important;
    }
}