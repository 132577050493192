.daily-report-container button.btn-print {
  border: 2px solid #f84e4e;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 13px 29px;
  color: #f84e4e;
  background-color: transparent;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  border-color: black;
  color: black;
  margin-bottom: 10px;
}

.daily-report-table-container table {
  width: 100%;
  background-color: #fff;
  border-radius: 6px;
  /* text-align: center; */
}

.daily-report-table-container table tfoot {
  background-color: #e5e5e5;
}

.daily-report-table-container table tfoot tr td span {
  padding: 10px;
  background-color: #fff;
  display: flex;
  flex: 1;
  justify-content: space-between;
  border: 1px solid #e9e9e9;
  border-radius: 6px;
}
.daily-report-table-container table tfoot tr td span {
  font-weight: normal;
  font-size: 14px;
  color: #333333;
}
.daily-report-table-container table tfoot tr td span b {
  font-weight: normal;
  font-size: 14px;
  color: #7d7d7d;
}

.daily-report-table-container th,
td {
  padding: 12px;
}

.daily-report-table-container table thead tr th {
  color: #7d7d7d;
  font-size: 14px;
  font-weight: normal;
}

.daily-report-table-container table tbody tr td {
  color: #333333;
  font-size: 14px;
  font-weight: normal;
}

.daily-report-table-container table tr th:first-child,
.daily-report-table-container table tr td:first-child {
  border-right: 1px solid #f1f1f1;
}

.daily-report-table-container table tr th:last-child,
.daily-report-table-container table tr td:last-child {
  border-left: 1px solid #f1f1f1;
}

.daily-report-table-container table tr th,
.daily-report-table-container table tr td {
  border-bottom: 1px solid #f1f1f1;
}

.daily-report-table-container table tr:last-child td {
  border-bottom: none;
}

.daily-report-table-container table tr th:first-child,
.daily-report-table-container table tr td:first-child {
  text-align: left;
}

.daily-report-table-container table tbody tr td:first-child span {
  color: #7d7d7d;
  font-size: 10px;
  font-weight: normal;
}

.daily-report-total-count-mobile {
  padding: 0;
  padding-top: 10px;
  padding-right: 5px;
}

.daily-report-total-amount-mobile {
  padding: 0;
  padding-top: 10px;
  padding-left: 5px;
}

.daily-report-table-container table tfoot tr td {
  border: none !important;
}
