.confrimpopup .modal-body {
    width: 100%;
    padding: 24px 15px !important;
    text-align: left;
}

.confrimpopup .modal-body h2 {
    width: 100%;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 115%;
    /* identical to box height, or 21px */

    letter-spacing: 0.023em;

    color: #000000;
    margin-bottom: 25px;
}

.confrimpopup .modal-body p {
    margin-top: 20px;
}

.confrimpopup .btn-group {
    width: 100%;
    height: auto;
    margin-top: 50px;
    display: flex;
}

.confrimpopup .btn-yes,
.confrimpopup .btn-no {
    height: 54px;
    border-radius: 6px;
}

.confrimpopup .btn-yes {
    background: #ffbf2b;
    border-radius: 6px;
    margin-left: 10px;
    height: 54px;
    border: none !important;
    flex: 1;
}

.confrimpopup .btn-no {
    width: 120px;
    border: 1px solid #b7b0b0;
    box-sizing: border-box;
    background: #ffffff;
    color: #000
}

.confrimpopup .btn-yes:disabled {
    color: #c3a96e;
    border: none !important;
    background: #fff4dc;
}