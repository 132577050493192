.textbox-container {
  height: 54px;
  width: 100%;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 14px;
  background: #fbfbfb;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 6px;
}

.textbox-container .left-icon {
  width: 16px;
}

.textbox-container .prefix-phone-number {
  width: 50px;
  height: 100%;
  line-height: 4 !important;
  color: #a9a9a9;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  border-right: 1px solid #eaeaea;
  padding-right: 10px;
  margin-left: 10px;
}

.textbox-container input {
  flex-grow: 1;
  width: 100%;
  background-color: transparent;
  border: none !important;
  outline: none !important;
  margin-left: 14px;
  margin-right: 14px;
}

.textbox-container input:focus-within,
.textbox-container input:focus-visible,
.textbox-container input:hover,
.textbox-container input:focus {
  border: none !important;
}

.textbox-container input:read-only {
  color: #737373;
}
.textbox-container input::placeholder {
  color: #a9a9a9;
}
