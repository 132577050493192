.setting-basic-container .checkbox-container {
    background: #E4E4E4;
    border-radius: 6px;
    padding: 8px;
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-bottom: 10px;
}

.setting-basic-container .checkbox-container .checkmark {
    left: unset;
    right: 8px;
}

.setting-basic-container .checkbox-container label {
    flex-grow: 1;
}

.setting-basic-container .checkbox-container .checkbox-input-container {
    display: flex;
    padding: 5px;
    background: #FFFFFF;
    border: 1px solid #F8F8F8;
    box-sizing: border-box;
    border-radius: 6px;
}

.setting-basic-container .checkbox-container .checkbox-input-container input {
    height: 18px;
    width: 18px;
    border-radius: 4px;
}

.setting-basic-container .checkbox-container .checkbox-input-container input[type="checkbox"] {
    background: #FFFFFF;
    border: 1px solid #F8F8F8;
    box-sizing: border-box;
    border-radius: 6px;
}

.setting-basic-container .textbox-section {
    display: flex;
}

.setting-basic-container .textbox-section .textbox-item {
    flex: 1;
    width: 50px;
    padding: 5px;
}

.setting-basic-container .textbox-section .textbox-item .textbox-section-container {
    width: 100%;
}

.setting-basic-container .textbox-section .textbox-item label {
    color: #9F9F9F;
    font-size: 12px;
    margin-bottom: 5px;
}

.setting-basic-container .textbox-section .textbox-item .textbox-section-container input:placeholder-shown {

    color: #CECECE;
}


.setting-basic-container .textbox-section .textbox-item .textbox-section-container input {
    width: 100%;
    font-size: 14px;
    padding: 10px;
    background: #FFFFFF;
    color: #000;
    border: 1px solid #F8F8F8;
    box-sizing: border-box;
    border-radius: 6px;
}

.setting-basic-container .btn-done {
    margin-top: 20px;
    width: 100%;
    height: 45px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */

    text-align: center;

    color: #FFFFFF;

    background: #333333;
    border-radius: 6px;
}