.btn-default {
  height: 54px;
  border-radius: 6px;

  border: 1px solid #b7b0b0;
  background: #333333;
  color: #ffffff;
}

.btn-main {
  height: 54px;
  border-radius: 6px;
  width: 100%;
  border: none !important;
  background: #333333;
  color: #ffffff;
}

.btn-main:disabled {
  color: #c3a96e;
  border: none !important;
  background: #fff4dc;
}