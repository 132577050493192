.card-info-container {
    width: 100%;
    /* height: 95px; */
    background-color: #6dc287;
    border-radius: 6px;
    margin-top: 10px;
    padding: 10px 10px 6px;
    color: #fff;
    cursor: pointer;
}

.card-info-container:last-child {
    margin-bottom: 10px;
}

.card-info-container .top,
.card-info-container .middle,
.card-info-container .bottom {
    display: flex;
}

.card-info-container .top {
    font-size: 14px;
}

.card-info-container .top .left b {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
}

.card-info-container .middle {
    margin: 3px 0;
    font-size: 14px;
    color: #CCF4D8;
}

.card-info-container .bottom {
    margin-left: -10px !important;
    margin-right: -10px;
    height: 34px;
    padding: 10px 10px;
    font-size: 12px;
    margin-top: 8px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.card-info-container .top div,
.card-info-container .middle div,
.card-info-container .bottom div {
    flex: 1;
}

.card-info-container .bottom .left b {
    padding: 2px 5px;
    background-color: #F6EFA8;
    border-radius: 5px;
    color: #655C03;
    font-weight: 500;
}

.card-info-container .top div:last-child,
.card-info-container .middle div:last-child,
.card-info-container .bottom div:last-child {
    text-align: right;
    min-width: max-content;
}

.card-info-container .bottom {
    background-color: #5DAC75;
}



.in-progress {
    background-color: #ffffff;
    color: #000;
}

.in-progress .middle {
    color: #000;
}

.in-progress .bottom {
    background: #F8F8F8;
}

.in-progress .bottom .left {
    color: #9F9F9F
}


.in-progress .bottom .right {
    /* color: #E15C5C */
    color: #9F9F9F;
}

.in-progress .top .right button {
    background: #333333;
    border-radius: 6px;
    border: 0;
    color: #ffffff;
    padding: 10px
}

.in-progress .bottom .left b {

    padding: unset;
    background: unset;
    border-radius: unset;
}


.selected {
    background-color: #4389F3;
    color: #ffffff;
    cursor: unset;
}

.selected .middle {
    color: #C0C6C2
}

.selected .bottom, .selected .bottom.store-type-pos {
    background-color: #3174D9;
}



.selected .bottom .right,
.selected .bottom .left {
    color: #F1F1F1;
}

.in-progress.selected .bottom .left b {
    color: #F1F1F1;
    padding: unset;
    background: unset;
    border-radius: unset;
}

.selected .bottom .left b {
    background-color: #fff;
    color: #3174D9;
}

.selected .top .right button {
    background: #ffffff;
    border-radius: 6px;
    border: 0;
    color: #333333;
    padding: 10px
}
.card-info-container.store-type-pos{
    background-color: #ffc40d;
}
.bottom.store-type-pos{
    background-color: #e3a21a;
}