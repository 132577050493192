.orders-container {}

.orders-navbars {}

.orders-container .parent {
    padding-top: 20px;
}

.orders-container .nav {
    flex-wrap: nowrap !important;
}

.orders-container .card-detail-section {
    /* border: 1px solid #d6d6d6; */
}

.orders-container .in-progress-section {
    border: 1px solid #d6d6d6;
}

.orders-container .in-progress-section .title {
    background: #dadada !important;
    border-radius: 6px !important;
    padding: 17px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */


    color: #686D74;
}