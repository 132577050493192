.setting-basic-box .modal-header div {
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    flex-grow: 1;
}

.setting-basic-box .modal-sm {
    max-width: 400px !important;
}


@media (min-width: 576px) {
    .setting-basic-box .modal-sm {
        max-width: 400px !important;
    }
}

.setting-basic-box .modal-body,
.setting-basic-box .modal-header {
    background: #F0F0F0;
}