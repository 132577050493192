.order-list-container {
    overflow: auto;
    height: 580px;
}

.order-list-container .empty-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 50px;
}

.order-list-container .order-list-loading,
.order-list-container .order-list-more-data-loading {
    display: inline-block;
    align-items: center;
    justify-items: center;
    text-align: center;
    width: 100%;
    padding-top: 100px;
}

.order-list-container .order-list-more-data-loading {

    padding-top: 20px;
}

.order-list-container .empty-list img {
    max-width: 250px;
}

.order-list-container .inprogressempty img {
    max-width: 250px;
    margin-top: 55px;
}

.order-list-container .empty-list p {
    margin-top: 50px;
}

@media screen and (max-width: 992px) {
    .order-list-container {
        overflow: auto;
        height: 170px;
    }

    .order-list-container .empty-list {
        margin-top: 20px;
    }

    .order-list-container .empty-list img,
    .order-list-container .inprogressempty img {
        max-width: 80px;
    }

    .order-list-container .empty-list p {
        margin-top: 10px;
    }


    .order-list-container .inprogressempty img {
        margin: 0;
    }

}